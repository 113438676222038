.App {
  text-align: center;
}

body,
html {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
